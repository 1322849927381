import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrettyPricePipe} from './pipes/pretty-price.pipe';
import {ReplaceAllPipe} from './pipes/replace-all.pipe';





@NgModule({
  declarations: [
    PrettyPricePipe,
    ReplaceAllPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PrettyPricePipe,
    ReplaceAllPipe,
  ]
})
export class SharedModule { }
