export class Merchant
{
  merchantId: any;
  address: string;
  latitude: string;
  longitude: string;
  backgroundImage: string;
  contactPhone: string;
  cuisine: string;
  deliveryEstimation: string;
  minDeliveryTax: string;
  freeDelivery: string;
  information: string;
  merchantName: string;
  openingHours: any;
  paymentList: {};
  ratingText: string;
  ratings: any;
  logo1: string;
  logo2: string;
  street: string;
  number: string;
  bairro: string;
  city: string;
  state: string;
  postCode: string;
  optionsMerchant: MerchantOptions;
  whatsapp: any;
  googleplay_app: string;
  googleplay_link: string;
  applestore_app: string;
  applestore_link: string;
  services: any;
  minimumOrder: any;
  seo: any;
  hours_pickup_list: any;
  hours_delivery_list: any;
  isHoursServiceDifferent: any;

  constructor() {
    this.paymentList = {};
  }
}

export class MerchantOptions
{
  id: string;
  android_push_key: string;
  banner: string;
  default_image: string;
  logo_app: string;
  default_lang: string;
  enabled_banner: string;
  enabled_fblogin: string;
  enabled_google: string;
  enabled_pushpic: string;
  fb_save_pic: string;
  help_url: string;
  location_accuracy: string;
  merchant_id: string;
  custom_color: string;
  primary_color_1: string;
  primary_color_2: string;
  secondary_color_1: string;
  secondary_color_2: string;
  light_color_1: string;
  light_color_2: string;
  dark_color_btn_1: string;
  dark_color_btn_2: string;
  privacy_url: string;
  push_icon: string;
  push_picture: string;
  terms_url: string;
  time_format: string;
  time_interval: string;
  facebook_link: string;
  instagram_link: string;
  youtube_link: string;
  twitter_link: string;
  googlemaps_key: string = '';
}
