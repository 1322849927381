import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyPrice'
})
export class PrettyPricePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    let price = '';
    if (isNaN(Number(value)))
    {
      price = value.toString();
    }
    else if (value !== null || value !== '' || value !== 0)
    {
      price = `${Number(value).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}`;
    }

    return price;
  }

}
