import { Injectable, EventEmitter } from '@angular/core';
import {FunctionsService} from './functions.service';
import {Address} from '../class/address';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  static address: any;
  static showInfoMerchant: boolean;
  static addressEmitter = new EventEmitter<boolean>();


  deliveryCharge: any;
  deliveryAddress: Address;
  distanceInfo: any;

  constructor(private functions: FunctionsService)
  {
    if (this.functions.getItemLocal('deliveryAddress'))
    {
      AddressService.address = this.functions.getItemLocal('deliveryAddress');
    }
  }



  changeAddress(): void
  {
    AddressService.addressEmitter.emit(true);
  }

  verifyAddressDelivery(): boolean
  {
    return !!AddressService.address;
  }
}
