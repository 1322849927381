import { Injectable } from '@angular/core';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  loader: Loader;
  isActiveMap: boolean;

  constructor()
  {
    this.isActiveMap = false;
  }

  loadMap(mapsKey: string = '', fn = () => {}): void
  {
    if (mapsKey)
    {
      this.isActiveMap = true;

      this.loader = new Loader({
        apiKey: mapsKey,
        version: 'weekly',
        libraries: ['places']
      });
      this.loader.load().then(fn);
    }

  }

}
