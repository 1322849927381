import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Observable} from 'rxjs';

import {ApiService} from '../services/api.service';
import {ApiResponse} from '../class/apiResponse';
import {Address} from '../class/address';
import {CartData, CartDetails, Details} from '../class/cart';
import {Cards, DeliveryFee, Payment} from '../class/delivery';
import {UserProfile} from '../class/user';
import {Pay} from '../class/pay';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {FunctionsService} from '../services/functions.service';
import {AddressService} from '../services/address.service';
import {CheckoutService} from '../services/checkout.service';
import {CartService} from '../services/new/cart.service';
import {CartService as CartService2} from '../services/cart.service';
import {Merchant} from '../class/merchant';
import {HideService} from '../services/hide.service';



@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  @ViewChild('divPaymentForm') divPayment: ElementRef;

  addressShow: boolean;
  paymentError: boolean;
  addressError: boolean;

  apiResponse: ApiResponse;
  service$: Observable<ApiResponse>;
  service2$: Observable<ApiResponse>;

  cart$: Observable<ApiResponse>;
  loadingCart: boolean;
  addressEvent$: Observable<boolean>;

  address: Address;
  addressList: Address[];

  cartData: CartData;
  deliveryFee: DeliveryFee;
  paymentList: Payment[];
  cardList: Cards;
  userProfile: UserProfile;

  paySelect: string;
  locationName = 'CASA';
  tmpPayment: string;
  localPayment: string;
  dontCharge: boolean;
  orderChange: boolean;

  deliveryEstimation: string;
  transactionType: string;
  merchantInfo: Merchant;

  deliveryCharge: any;

  clientName: string;

  constructor(
    private service: ApiService,
    private router: Router,
    private modalService: NgbModal,
    public functions: FunctionsService,
    public checkout: CheckoutService,
    public cartService: CartService)
  {
    this.checkout.payForm = new Pay();
    this.orderChange = false;
    this.cardList = new Cards();
    this.loadingCart = false;
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.merchantInfo = JSON.parse(this.functions.getItemLocal('merchantInfo'));
      this.deliveryEstimation = this.merchantInfo.deliveryEstimation;
      console.log(typeof this.merchantInfo.services);
    }
    this.cartService.loadCart();

    this.deliveryCharge = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;
    this.clientName = '';
  }

  ngOnInit(): void {
    this.functions.goToTop();
    this.localPayment = this.functions.getItemLocal('tmpPayment') ? this.functions.getItemLocal('tmpPayment') : '';
    if (this.localPayment)
    {
      const pay = this.localPayment.split('|');
      if (pay.length > 1)
      {
        this.selectPayment(pay[0], pay[1], pay[2]);
        this.checkout.payForm.payment_provider = this.tmpPayment;
      }
      else
      {
        this.localPayment = '';
        this.functions.setItemLocal('tmpPayment', '');
        this.checkout.payForm.payment_provider = '';
      }

    }
    // tslint:disable-next-line:max-line-length
    this.userProfile = this.functions.getItemLocal('userProfile') ? this.functions.getItemLocal('userProfile')  !== 'undefined' ? JSON.parse(this.functions.getItemLocal('userProfile')) : '' : '';
    this.cartService.loadCart();
    this.loadPaymentList();
  }


  loadAddress(): void
  {
    if (this.functions.getItemLocal('deliveryAddress'))
    {
      this.address = JSON.parse(this.functions.getItemLocal('deliveryAddress'));
      if (this.address.id)
      {
        this.setAddress();
      }
      else
      {

      }
    }
    else
    {
      this.service$ = this.service.get('getAddressBookList');

      this.service$.subscribe(data => {
          this.apiResponse = data;
          this.addressList = this.apiResponse.details.data;
          if (this.addressList)
          {
            for (const c of this.addressList)
            {
              if (c.as_default === '2' && this.cartService.cartDetails.street === '')
              {
                this.address = c;
                this.setAddress();
              }
            }
          }
        },
        error => this.functions.log(error) ,
        () => {

          /*this.setAddress();*/
        });
    }
    this.functions.log(this.checkout.payForm);
  }

  setAddress(): void
  {
    this.service2$ = this.service.setAddressBook(this.address.id, this.userProfile.contact_phone);
    this.service2$.subscribe(data => { },
      error => {},
      () => { this.getDeliveryFee(); });
  }

  transactionTypeChange(e): void
  {
    const type = e.target.value;
    this.transactionType = type;
    this.functions.setItemLocal('transaction_type', type);
    this.cartService.loadCart();
  }

  /*loadCart(): void
  {
    if (this.functions.getItemLocal('transaction_type'))
    {
      this.transactionType = this.functions.getItemLocal('transaction_type');
    }
    this.cart$ = this.service.loadCart(this.transactionType);
    this.loadingCart = true;
    this.cart$.subscribe(data => {
      this.apiResponse = data;
      if (this.apiResponse.code === 1)
      {
        this.functions.log(this.apiResponse);
        this.checkout.details = data.details;
        this.checkout.cartDetails = this.checkout.details.cart_details;
        this.cartData = this.checkout.details.data;
        this.functions.log(this.checkout.details);
        this.functions.log(this.checkout.cartDetails);
        let addonTotal = 0;
        for (const [k, v] of Object.entries(this.cartData.item))
        {
          addonTotal = 0;
          const sub: any = v.sub_item;
          if (sub)
          {
            for (const [l, m] of Object.entries(sub))
            {
              const s: any = m;
              if (s.addon_price)
              {
                addonTotal += Number(s.addon_price);
              }
            }
            this.cartData.item[k].discounted_price = Number(this.cartData.item[k].discounted_price) + addonTotal;
          }
        }
        this.checkout.payForm.transaction_type = this.checkout.details.transaction_type;
        if (this.cartData.total.less_voucher)
        {
          this.cartData.total.total = Number(this.cartData.total.total) - Number(this.cartData.total.less_voucher);
        }
      }
    },
      error => {},
      () => {this.loadAddress(); this.loadingCart = false; });
  }*/

  loadPaymentList(): void
  {
    this.service$ = this.service.get('loadPaymentList');
    this.service$.subscribe(data => {
      this.apiResponse = data;
      this.paymentList = this.apiResponse.details.data;
      this.functions.log(this.paymentList);
      for (const [k, v] of Object.entries(this.paymentList))
      {
        if (v.payment_name === 'Cartão na entrega')
        {
          delete this.paymentList[k];
          break;
        }
      }
      const tmp = [];
      for (const [k, v] of Object.entries(this.paymentList))
      {
        if (v)
        {
          tmp.push(v);
        }
      }
      this.paymentList = tmp;

    }, error => {

    }, () => {
      this.service$ = this.service.get('getPayondeliverycards');
      this.service$.subscribe(data => {
        this.apiResponse = data;

        for (const [k, v] of Object.entries(this.apiResponse.details.data))
        {
          this.cardList = this.apiResponse.details.data[k];
          this.paymentList.push({payment_code: 'pyr' , payment_name: this.cardList.payment_name, img: this.cardList.payment_logo});
        }
      });
    });
  }

  getDeliveryFee(): void
  {
    this.service$ = this.service.getDeliveryFee(this.address.address);
    this.service$.subscribe(data => {
      this.apiResponse = data;
      this.deliveryFee = data.details;
    });
  }



  openModalAddress(modal): void
  {
    this.modalService.open(modal, {ariaLabelledBy: 'Address'});
  }

  openModal(content): void
  {
    this.modalService.open(content);
    this.localPayment = this.functions.getItemLocal('tmpPayment') ? this.functions.getItemLocal('tmpPayment') : '';
  }



  selectPayment(cod, name, img ): void
  {
    this.tmpPayment = cod;
    this.paySelect = name;
    this.orderChange = false;
    this.functions.setItemLocal('tmpPayment', this.tmpPayment + '|' + this.paySelect + '|' + (img && img !== 'undefined' ? img : ''));
    switch (cod)
    {
      case 'cod':
        this.paySelect = 'Dinheiro na entrega';
        this.orderChange = true;
        break;


      case 'pyr':
        this.dontCharge = false;
        this.functions.setItemLocal('dontCharge', '');
        if (img && img !== 'undefined')
        {
          this.paySelect = `<small>Cartão na entrega:</small><br> <img src="${img}" class="img-fluid mr-1" width="30"> ${name} `;
        }
        else
        {
          this.paySelect = `Cartão na entrega: ${name} `;
        }
        this.checkout.payForm.selected_card = name;
        break;


      default:
        this.paySelect = name;
        this.dontCharge = false;
        break;
    }
    this.checkout.payForm.order_change = '0';
  }

  maskMoney(e): void
  {
    this.functions.log(Number(e.target.value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}));
  }

  savePayment(): void
  {
    if (this.orderChange)
    {
      if (this.dontCharge)
      {
        this.checkout.payForm.order_change = '0';
      }
      else if (this.checkout.payForm.order_change !== '0')
      {

      }
      else
      {
        this.functions.showAlertError('Você deve informar o troco!');
        return;
      }
      if (!this.checkout.payForm.order_change)
      {

      }
    }
    else
    {
      this.dontCharge = false;
    }

    if (this.tmpPayment === 'cod')
    {
      this.paySelect = 'Dinheiro na entrega';
      if (this.checkout.payForm.order_change !== '0')
      {
        this.paySelect += `: <b> Troco para ${this.functions.prettyPrice(this.checkout.payForm.order_change)}</b>`;
      }
    }

    this.checkout.payForm.payment_provider = this.tmpPayment;
    this.modalService.dismissAll();
    this.paymentError = false;
    this.functions.log(this.divPayment);
    this.divPayment.nativeElement.classList.remove('error');
  }

  applyVoucher(voucher): void
  {
    if (voucher !== '')
    {
      this.service$ = this.service.applyVoucher(voucher);
      this.service$.subscribe(data => {
        this.apiResponse = data;
        console.log(data);
        if (this.apiResponse.code === 1)
        {
          this.functions.showAlertSuccess(this.apiResponse.msg, 'Cupom aplicado com sucesso!');
        }
        else if (this.apiResponse.code === 2)
        {
          this.functions.showAlertError(this.apiResponse.msg);
        }
      },
        error => {},
        () => {

          this.cartService.loadCart();
        });
    }
  }

  removeVoucher(): void
  {
    this.service$ = this.service.removeVoucher();
    this.service$.subscribe(data => {
      this.functions.log(data);
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg, 'Cupom removido');
      }
    }, error => {
      this.functions.log(error);
      this.functions.showAlertErroServer();
    }, () => {
      this.cartService.loadCart();
    });
  }




}
