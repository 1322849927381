import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HideService {

  public static event: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor()
  {
  }
}
