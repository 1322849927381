<!-- MODAL HEADER -->

<div class="modal-header-mechant">
    <div class="logo-image-merchant rounded mr-2 brand-wrap ">
        <img src="{{merchant.optionsMerchant.logo_app}}">
    </div>
    <h4 id="exampleModalLabel">{{merchant.merchantName}}</h4>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body bg-light pb-4 p-3">

    <div class="mb-3 px-sm-2">
            <h6 class="font-weight-light text-dark my-2 d-flex"> 
                <i class="feather-map-pin mr-1 h5"></i> 
                <span>
                    {{merchant.street}}, {{merchant.number}}<br> 
                    <small>{{merchant.bairro}} - {{merchant.city}} - {{merchant.state}} {{merchant.postCode}}</small>
                </span> 
            </h6>
            <hr>
            <h6 class="font-weight-light text-dark my-2 d-flex">
                <i class="feather-message-circle mr-1 h5"></i>
                <span>
                    <a class="d-flex-wrap" [href]="whatsapp"> {{merchant.contactPhone | mask: '(00) 00000-0000'}}
                        <span *ngIf="merchant.whatsapp === '1'" class="font-weight-light badge badge-success small mt-1 mt-sm-0 ml-auto">
                            <small>CLIQUE PARA CHAMAR</small><br><i class="feather-message-circle mr-1"></i>WHATS APP
                        </span>
                    </a>
                </span>
            </h6>
            <hr>
        
            <div class="row">
                <div class="col">
                    <p class="font-weight-bold m-0">Entrega </p>
                    <p class="text-muted m-0">{{deliveryCharge ? functions.prettyPrice(deliveryCharge) : ''}}</p>
                    <small *ngIf="!deliveryCharge">É preciso informar um endereço</small>
                </div>
                <!--
                <div class="col">
                    <p class="font-weight-bold m-0">Tempo</p>
                    <p class="text-muted m-0">{{merchant.deliveryEstimation}}</p>
                </div>-->
                <div class="col">
                    <!-- HORÁRIO QUANDO FECHADO  -->
                    <p *ngIf="!open" class="m-0"><span class="badge badge-warning small">FECHADO AGORA</span></p>
                    <p *ngIf="!open" class="m-0 small">{{closeText}}</p>
                    <p *ngIf="!open" class="m-0 ">{{closeHour}}</p>
                    <!-- HORÁRIO QUANDO ABERTO -->
                    <p *ngIf="open" class="font-weight-bold m-0">Aberto hoje</p>
                    <p *ngIf="open" class="text-muted m-0">{{hoursToday}}</p>
                </div>
            </div>
    </div>
    <div class="box shadow-sm rounded bg-white mb-3 p-3">
        <h5 class="text-dark d-flex"><i class="feather-message-circle h5 text-primary mr-2"></i>
            <span>Informações</span>
        </h5>
        <p class="mb-0 text-muted">{{merchant.information}}</p>
    </div>
    <div class="box shadow-sm rounded bg-white mb-3 p-3">
        <h5 class="text-dark d-flex"> <i class="feather-dollar-sign h5 text-primary mr-2"></i> 
            <span>Opções de pagamento</span>
        </h5>
        <p class="mb-1 mt-3" *ngFor="let payment of merchant.paymentList | keyvalue">
            <i class="feather-chevrons-right icon-color-02"></i>
                {{payment.value}}
            <!--EXIBIÇÃO DAS BANDEIRAS DE CARTÃO-->
            <span *ngIf="payment.key === 'pyr'">
                <br>
                <span   *ngFor="let card of cardList">
                &nbsp;
                    <img src="{{card.payment_logo}}" alt="{{card.payment_name}}" class="img-fluid" width="25">
                </span>
            </span>
            <!--FIM DAS BANDEIRAS-->
        </p>
    </div>
    <div class="box shadow-sm rounded bg-white mb-3 p-3">
        <h5 class="text-dar d-flex"><i class="feather-clock h5 text-primary mr-2"></i> 
            <span>Horário de {{merchant.isHoursServiceDifferent ? 'Entrega' : 'Funcionamento'}}</span> </h5>
        <p class="mb-1" *ngFor="let open of merchant.hours_delivery_list">
            <i class="feather-chevrons-right icon-color-02"></i> {{open.day}} 
            <span class="float-right text-dark" *ngIf="open.hours"> {{open.hours}} </span>
            <span class="float-right badge badge-danger" *ngIf="!open.hours">FECHADO</span>
        </p>
    </div>

    <div class="box shadow-sm rounded bg-white mb-3 p-3" *ngIf="merchant.isHoursServiceDifferent">
        <h5 class="text-dar d-flex"><i class="feather-clock h5 text-primary mr-2"></i>
            <span>Horário de Retirada</span> </h5>
        <p class="mb-1" *ngFor="let open of merchant.hours_pickup_list">
            <i class="feather-chevrons-right icon-color-02"></i> {{open.day}}
            <span class="float-right text-dark" *ngIf="open.hours"> {{open.hours}} </span>
            <span class="float-right badge badge-danger" *ngIf="!open.hours">FECHADO</span>
        </p>
    </div>
    <h6 class="text-dark m-2 pt-3">Onde estamos</h6>


</div>

<div class="modal-footer border-top p-0 ">
    <div class="col-12 m-1 p-0">
        <button type="button" class="btn btn-block btn-lg" (click)="closeModal()"> <i class="feather-x-circle"></i> Fechar</button>
    </div>
</div>
