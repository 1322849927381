import { Component, OnInit } from '@angular/core';
import {Merchant} from '../../class/merchant';
import {FunctionsService} from '../../services/functions.service';
import {Page} from '../../class/page';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {ApiService} from '../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css']
})
export class InfoModalComponent implements OnInit {

  service$: Observable<ApiResponse>;

  merchant: Merchant;
  locationMerchant: any;
  cardList: any;


  deliveryCharge: any;

  pagesGeneric: Page;
  pagesGeneric$: Observable<ApiResponse>;

  dateNow: Date;
  daysWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  hoursToday: string;
  open: boolean;
  closeText: string;
  closeHour: string;

  whatsapp: string;
  msgWhats: string;

  constructor(
      private service: ApiService,
      public functions: FunctionsService,
      private modalService: NgbModal)
  {
    this.merchant = new Merchant();
    this.dateNow = new Date();
    this.locationMerchant = false;
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
      this.locationMerchant = {lat: Number(this.merchant.latitude), lng: Number(this.merchant.longitude)};
      this.getHoursMerchant(this.merchant.openingHours);
      this.deliveryCharge = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;

      this.msgWhats = 'Olá, estou no APP de vocês e gostaria de informações';
      if (this.merchant.whatsapp === '1')
      {
        this.whatsapp = `https://api.whatsapp.com/send?phone=+55${this.merchant.contactPhone}&text=${this.msgWhats}`;
      }
      else
      {
        this.whatsapp = this.functions.sanitizerLink('javascript:;');
      }
    }
  }

  ngOnInit(): void {
  }

  closeModal(): void
  {
    this.modalService.dismissAll();
  }

  loadPaymentCardList(): void
  {
    this.service$ = this.service.get('getPayondeliverycards');
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.cardList = data.details.data;
      }
    });
  }



  getPagesGeneric(): void
  {
    this.pagesGeneric$ = this.service.get('getPagesGenericAll');
    this.pagesGeneric$.subscribe(data => {
      if (data.code === 1)
      {
        this.pagesGeneric = data.details.data;
      }
    });
  }

  showPageCustom(identify): void
  {
    this.functions.redirectPageCustom(identify);
    this.modalService.dismissAll();
  }

  getHoursMerchant(hoursMerchant): void
  {
    for (const [k, v] of Object.entries(hoursMerchant))
    {
      // @ts-ignore
      if (v.day === this.daysWeek[this.dateNow.getDay()])
      {
        const hourNow = `${('0' + this.dateNow.getHours()).slice(-2)}:${String(this.dateNow.getMinutes()).padStart(2, '0')}`;
        // @ts-ignore
        if (v.hours)
        {
          // @ts-ignore
          const tmp = v.hours.split('/');
          const am = tmp[0] ? tmp[0] : '';
          const pm = tmp[1] ? tmp[1] : '';
          if (am.split('-')[0].trim() <= hourNow && am.split('-')[1].trim() > hourNow)
          {
            /*console.log('Aberto Primeiro Horario');*/
            this.open = true;
            this.hoursToday = am;
            break;
          }
          else if (am.split('-')[0].trim() > hourNow)
          {
            this.open = false;
            this.closeText = 'ABRIRÁ HOJE';
            this.closeHour = am;
            break;
          }
          else
          {
            if (pm)
            {
              this.functions.log(pm.split('-')[0].trim() < hourNow );
              if (pm.split('-')[0].trim() <= hourNow && pm.split('-')[1].trim() > hourNow)
              {
                /*console.log('Aberto Segundo Horario');*/
                this.open = true;
                this.hoursToday = pm;
                break;
              }
              else
              {
                this.closeText = 'Abrirá hoje';
                this.closeHour = pm;
                break;
              }
            }
            else
            {
              this.closeText = 'Abrirá ' + hoursMerchant[Number(k) + 1].day;
              this.closeHour = hoursMerchant[Number(k) + 1].hours;
              this.open = false;
              break;
            }
          }
          this.closeText = 'Abrirá ';
          this.closeInfo(hoursMerchant, k);
          this.open = false;
        }
        else
        {
          this.closeText = 'Abrirá ';
          this.closeInfo(hoursMerchant, k);
          this.open = false;
        }
        break;
      }
      else
      {
        this.open = false;
      }
    }
    this.functions.setItemLocal('open', this.open ? '777' : '0');
    if (!this.open)
    {
      this.functions.setItemLocal('txtClose', JSON.stringify({closeText: this.closeText, closeHours: this.closeHour}));
    }
  }

  closeInfo(hoursMerchant, key): void
  {
    for (const [i, h] of Object.entries(hoursMerchant))
    {
      if (key === i && hoursMerchant[i].hours)
      {
        /*console.log('IF horario');*/
        this.closeText += `${hoursMerchant[i].day}`;
        this.closeHour = `${hoursMerchant[i].hours.split('/').join(' ')}`;
        break;
      }
      else
      {
        let firstDay = true;
        /*console.log('ELSE horario');*/
        if (hoursMerchant[Number(key) + 1].hours)
        {
          this.closeText += `${hoursMerchant[Number(key) + 1].day}`;
          this.closeHour = `${hoursMerchant[Number(key) + 1].hours.split('/').join(' ')}`;
          firstDay = false;
          break;
        }
        else if (i > key && hoursMerchant[i].hours)
        {
          this.functions.log(hoursMerchant[i].hours);
          this.closeText += `${hoursMerchant[i].day}`;
          this.closeHour = `${hoursMerchant[i].hours.split('/').join(' ')}`;
          firstDay = false;
          break;
        }
        /*else if (firstDay)
        {
          this.closeText += `${hoursMerchant[0].day}`;
          this.closeHour = hoursMerchant[0].hours ? `${hoursMerchant[0].hours.split('/').join(' ')}` : '';
          break;
        }*/

      }
    }
    /*if (hoursMerchant[Number(key) + 1] && hoursMerchant[Number(key) + 1].hours)
    {
      console.log(hoursMerchant[Number(key) + 1]);
      this.closeText += `${hoursMerchant[Number(key) + 1].day}`;
      this.closeHour = `${hoursMerchant[Number(key) + 1].hours.split('/').join(' ')}`;
    }
    else
    {
      this.closeText += `${hoursMerchant[0].day}`;
      this.closeHour = `${hoursMerchant[0].hours.split('/')}`;
    }*/
  }


}
