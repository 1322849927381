import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  static emitterAddCart = new EventEmitter<string>();
  static emitterChangeButton = new EventEmitter<boolean>();
  private emitterAddCart$: EventEmitter<string>;
  private emitterChangeButton$: EventEmitter<boolean>;

  constructor()
  {
  }


  eventCart(msg: string): void
  {
    this.emitterAddCart$ = CartService.emitterAddCart;
    this.emitterAddCart$.emit(msg);
  }

  addCart(): void
  {
    this.emitterAddCart$ = CartService.emitterAddCart;
    this.emitterAddCart$.emit('updateCart');
  }

  enterPayment(): void
  {
    this.emitterChangeButton$ = CartService.emitterChangeButton;;
    this.emitterChangeButton$.emit(true);
  }

}
