<app-nav-header-payment></app-nav-header-payment>
<div class="mt-menu-header">

  <div class="d-none fixed-top">
    <div class="bg-primary border-bottom p-3 d-flex align-items-center">
        <a href="javascript:;" routerLink="/home"><i class="feather-arrow-left h3 font-weight-bold text-white mr-2"></i></a>
        <h5 class="font-weight-bold m-0 text-white">Confirmação de pedido</h5>
    </div>
  </div>

  <!-- checkout -->
  <div class="container position-relative">
    <div class="py-4 row mb-5 mt-5 mt-md-2 mb-sm-0">
      <div class="col-md-8 col-sm-7 mb-3">
        <div>

          <div #divPaymentForm id="__payment_form" class="mb-3 rounded shadow-sm bg-white overflow-hidden">
            <div class="bg-white p-3">
              <div class="d-flex flex-column">
                <h6 class="mb-0 mb-sm-3 font-weight-bold d-flex">Forma de pagamento
                  <small class="ml-auto">
                    <button class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" (click)="openModal(modalPaymentList)">
                      <i class="feather-plus font-weight-bold"></i>
                      <span class="text-center"> ESCOLHER</span>
                    </button>
                  </small>
                </h6>

                <small class="text-danger" *ngIf="paymentError">Selecione a forma de pagamento</small>

                <div *ngIf="checkout.payForm.payment_provider">
                  <p [innerHTML]="paySelect" class="m-0"></p>
                </div>

              </div>
            </div>
          </div>

          <div class="mb-3 rounded shadow-sm bg-white overflow-hidden" *ngIf="!cartService.loading">
            <div class="bg-white p-3">
              <div class="row">
                <div class="form-group col-12">
                  <label>NOME:</label>
                  <input type="text" class="form-control" [(ngModel)]="checkout.payForm.clientName" required>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!---- CARRINHO LATERAL -->
      <div class="col-md-4 col-sm-5 mb-5 mb-sm-0">
        <app-cart-payment></app-cart-payment>
      </div>
    </div>
  </div>
</div>

<ng-template #modalPaymentList let-modal>
  <div class="modal-header shadow">
    <h5 class="m-0">Formas de Pagamento</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close"></button>
  </div>

  <div class="modal-body modal-body-70vh p-3">
    <p>Escolha uma das opções aceitas por esse estabelecimento</p>
    <div class="filter">
      <h5>Pagamento na entrega</h5>
      <div class="custom-control  border-bottom px-0 custom-radio" *ngFor="let pay of paymentList">
        <input
                #p
                type="radio"
                id="_{{pay.payment_name}}"
                name="address"
                [checked]="localPayment === pay.payment_code + '|' + pay.payment_name + '|' + (pay.img ? pay.img : '')"
                class="custom-control-input"
                (click)="selectPayment(pay.payment_code, pay.payment_name, pay.img)">
        <label class="custom-control-label py-3 w-100 px-3" for="_{{pay.payment_name}}"><img *ngIf="pay.img" src="{{pay.img}}" class="img-fluid" width="20"> {{pay.payment_name}}</label>

        <div class="d-flex align-items-center">
          <div class="form-group" style="width: 90px;" *ngIf="pay.payment_code === 'cod'" [style.display]="orderChange ? '' : 'none'">
            <label>Troco?</label>
            <input type="text" currencyMask class="form-control" placeholder="R$        ,      " [(ngModel)]="checkout.payForm.order_change">
          </div>
          <div class="form-group" *ngIf="pay.payment_code === 'cod'" [style.display]="orderChange ? '' : 'none'">
            <label class="form-label"></label>
            <div class="custom-checkbox custom-control custom-checkbox-charge" >
              <input #dontChargeEl id="dontCharge" type="checkbox" class="custom-control-input" name="dontCharge" [(ngModel)]="dontCharge">
              <label for="dontCharge" class="custom-control-label">Não preciso de troco</label>
            </div>
          </div>
          

        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer p-0">
    <div class="col-6 m-0 p-0">
      <button class="btn btn-lg btn-block" (click)="modal.dismiss('Cross click')">Voltar</button>
    </div>
    <div class="col-6 m-0 p-0">
      <button class="btn btn-primary btn-lg btn-block" (click)="savePayment()">ESCOLHER</button>
    </div>
  </div>
</ng-template>



<ng-template #modalAddress let-modalAddress>
  <app-address></app-address>
</ng-template>



<!--
<div class="osahan-payment" *ngIf="service$ | async as apiResponse ; else loading">

  <div class="mt-5 p-0">
    <div class="bg-primary shadow p-3">
       <a class="hc-nav-back text-white" routerLink="/cart"><span><i class="feather-arrow-left-circle"></i></span></a>
       <h4 class="m-0 pt-1 text-white pl-5">CONFIRMAR PEDIDO</h4>
    </div>
  </div>

  &lt;!&ndash; checkout ADDRESS &ndash;&gt;
  <form #formPayNow='ngForm' (ngSubmit)="validPayNow(formPayNow)">
    <input type="hidden" name='transaction_type' [(ngModel)]="payForm.transaction_type" required>
    <input type="hidden" name="payment_provider" [(ngModel)]="payForm.payment_provider" required>
    <input type="hidden" name="delivery_date" [(ngModel)]="payForm.delivery_date" required>
    <input type="hidden" name="order_change" [(ngModel)]="payForm.order_change" required>
    <input type="hidden" name="delivery_asap" [(ngModel)]="payForm.delivery_asap" required>

    <div class="p-3 mt-6">
      <div class="card-item mb-3" *ngIf="details.transaction_type === 'delivery'">
        <div id="Address" class="osahan-cart-item-profile bg-white rounded shadow p-3" [class.error]="addressError">
          <div class="d-flex flex-column">
            <h6 class="mb-2 font-weight-bold d-flex">Endereço de entrega
              <small class="text-danger" *ngIf="addressError">Selecione um endereço para entrega</small>
              <small class="ml-auto">
                <button class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" [routerLink]="['/address', 'payment']" >
                  <i class="feather-plus font-weight-bold"></i>
                  <span class="text-center"> ALTERAR</span>
                </button>
              </small>
            </h6>

            <div class="custom-control custom-radio mb-2 px-0" *ngIf="cartDetails.street">
              <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" checked="">
              <label class="custom-control-label border osahan-check p-3 w-100 rounded border-primary" for="customRadio1">
                <p class="m-0"> <b><i class="feather-home mr-2"></i>{{cartDetails.location_name}}</b> </p>
                <p class="mb-0 pl-4">{{cartDetails.street}}, {{cartDetails.number}} <br>
                  <small>{{cartDetails.bairro}} - {{cartDetails.city}} - {{cartDetails.state}}</small></p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card-item" >
        <div id="Payments" class="card-payment bg-white rounded shadow p-3" [class.error]="paymentError">
          <div class="d-flex flex-column">
            <h6 class="mb-1 font-weight-bold d-flex">Forma de pagamento

              <small class="ml-auto">
                <a class="btn btn-sm btn-outline-primary small p-1" href="javascript:;" (click)="openModal(modalPaymentList)" >
                  <i class="feather-dollar-sign font-weight-bold"></i>
                  <span class="text-center"> ESCOLHER</span>
                </a>
              </small>
            </h6>
            <small class="text-danger" *ngIf="paymentError">Selecione a forma de pagamento</small>
          </div>
          <div *ngIf="payForm.payment_provider">
            <p [innerHTML]="paySelect" class="m-0"></p>
          </div>
        </div>
      </div>

    </div>

  <div class="px-3 mb-7">
    &lt;!&ndash; checkout PAYMENT TYPE &ndash;&gt;

    &lt;!&ndash; checkout CUPOM &ndash;&gt;
    <div class="mb-3 shadow bg-white rounded p-3 mt-3 clearfix">
      <div class="input-group-sm mb-2 input-group">
        <input #voucher placeholder="Adicione o código do cupom" type="text" class="form-control">
        <div class="input-group-append">
          <button id="button-addon2" type="button" class="btn btn-primary" (click)="applyVoucher(voucher.value)">
            <i class="feather-percent"></i> Aplicar cupom
          </button>
        </div>
      </div>
      <div class="mb-0 input-group">
        <div class="input-group-prepend"><span class="input-group-text"><i class="feather-message-square"></i></span></div>
        <textarea placeholder="Alguma observação neste pedido?" aria-label="With textarea" class="form-control" name="delivery_instruction" [(ngModel)]="payForm.delivery_instruction"></textarea>
      </div>
    </div>

    &lt;!&ndash; checkout RESUME &ndash;&gt;
    <h5 class="mt-3 pt-2 pl-3 font-weight-bold">Resumo do pedido</h5>
    <div class="bg-white rounded shadow pt-2 mb-3">
      <div class="d-flex align-items-center justify-content-between px-3 py-2 border-bottom" *ngFor="let item of cartData.item | keyvalue ; let i = index">
        <div class="media">
          <div class="mr-2"> {{item.value.qty}} x</div>
          <div class="media-body">
            <p class="m-0">{{item.value.item_name}}<br>
              <small class="text-muted" *ngIf="item.value.qty > 1">{{item.value.qty}} x {{functions.prettyPrice(item.value.discounted_price)}}</small>
            </p>
          </div>
        </div>
        <div>
          <p class="text-gray mb-0 float-right ml-2 text-muted">{{functions.prettyPrice(item.value.discounted_price * item.value.qty)}}</p>
        </div>
      </div>
      <div class=" p-3 clearfix">
        <p class="mb-1">Sub Total <span class="float-right text-dark">{{functions.prettyPrice(cartData.total.subtotal)}}</span></p>
        <p class="mb-1" *ngIf="details.transaction_type === 'delivery'">Taxa de entrega <span class="text-info ml-1"><i class="icofont-info-circle"></i></span><span class="float-right text-dark">{{cartDetails.delivery_fee > 0 ? functions.prettyPrice(cartDetails.delivery_fee) : 'Selecione o endereço'}}</span></p>
        <p class="mb-1 text-success" *ngIf="cartData.total.less_voucher">Descontos<span class="float-right text-success" [textContent]="'- ' + functions.prettyPrice(cartData.total.less_voucher)"></span></p>
        <hr>
        <h6 class="font-weight-bold mb-3">TOTAL A PAGAR <span class="float-right" [textContent]="functions.prettyPrice(cartData.total.total)"></span></h6>
      </div>
    </div>
  </div>

  &lt;!&ndash; checkout CONFIRM&ndash;&gt;
    <div class="">
      <button class="btn btn-primary btn-block btn-lg" type="submit">
        CONFIRMAR PEDIDO <span class="font-weight-light  px-3">{{functions.prettyPrice(cartData.total.total)}}</span>
      </button>
    </div>
  </form>
  &lt;!&ndash; END PAYMENT OPTIONS - VIEWS FOR APP  &ndash;&gt;
</div>

&lt;!&ndash; Modal &ndash;&gt;


<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
-->
