import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from '../app.routing.module';
import {FooterBarComponent} from './footer-bar.component';



@NgModule({
  declarations: [
      FooterBarComponent,
  ],
  imports: [
    RouterModule,
    AppRoutingModule,
    CommonModule,
  ],
  exports: [FooterBarComponent],
})
export class FooterBarModule { }
