import {Directive, HostListener, Input, Output, EventEmitter, ElementRef} from '@angular/core';


@Directive({
  selector: '[appScrollSpy]'
})
export class ScrollSpyDirective {

  @Input() public spiedTags = [];
  @Output() public sectionChange = new EventEmitter<string>();
  private currentSection: string;

  constructor(private _el: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void
  {
    let currentSection: string;
    const children = this._el.nativeElement.children;
    const scrollTop = window.scrollY;
    /*const parentOffset = event.target.offsetTop;*/
    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {

        if ((element.offsetTop + 190) <= scrollTop) {
          currentSection = element.id;
        }
      }
    }
    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
      this.sectionChange.emit(this.currentSection);
    }
  }

}
