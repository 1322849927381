


<div class="m-0">
  <div class="bg-primary border-bottom p-3 d-flex align-items-center">
    <h4 class="m-0 pl-5 text-white ">ERRO DE CONEXÃO</h4>
  </div>
</div>
<div class="align-items-center text-center p-3 pt-5 pb-5 bg-white">
  <h5 class="mt-4">{{'Você esta sem conexão com a internet!'}}</h5>
  <a href="javascript:;" class="btn btn-primary m-4" (click)="verifyConnect()">TENTAR NOVAMENTE</a>
</div>
