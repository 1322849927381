export class Address
{
  id: string;
  address: string;
  as_default: string;
  street: string;
  number: string;
  bairro: string;
  complemento: string;
  reference: string;
  location_name: string;
  city: string;
  state: string;
  zipcode: string;
  country_code: string;
  latitude: string;
  longitude: string;

  constructor()
  {
    this.id = '';
    this.address = '';
    this.as_default = '';
    this.street = '';
    this.number = '';
    this.bairro = '';
    this.complemento = '';
    this.reference = '';
    this.location_name = '';
    this.city = '';
    this.state = '';
    this.zipcode = '';
    this.country_code = '';
    this.latitude = '';
    this.longitude = '';
  }
}

export class AddressCompone
{
  long_name: string;
  short_name: string;
}


export class CEPApi
{
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}
