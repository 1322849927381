import {AfterViewInit, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ApiResponse} from '../class/apiResponse';
import {Merchant} from '../class/merchant';
import {Category} from '../class/category';
import {ApiService} from '../services/api.service';
import {Observable} from 'rxjs';
import {NgbModal, NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import {FunctionsService} from '../services/functions.service';
import {Page} from '../class/page';
import {group} from '@angular/animations';
import {PageScrollConfig} from 'ng2-page-scroll';
import {AddressService} from '../services/address.service';
import {CartService} from '../services/new/cart.service';
import {ModalService} from '../services/new/modal.service';
import {MenuService} from '../menu/menu.service';
import {Meta} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('tagMenu') tagMenu: ElementRef;
  @ViewChildren('titleCat') titleCat: QueryList<ElementRef>;
  apiResponse: ApiResponse;
  merchant: Merchant;
  merchant$: Observable<ApiResponse>;
  category: Category[];
  category$: Observable<ApiResponse>;
  service$: Observable<ApiResponse>;
  addressEvent$: Observable<boolean>;
  cartEvent$: Observable<string>;

  passId: string;
  cardList: any;

  pagesGeneric: Page;
  pagesGeneric$: Observable<ApiResponse>;

  tmp: any[];
  locationMerchant: any;
  ratings: number;
  listCategory: NodeList;
  listCategoryA: NodeList;
  refCategory: any[];
  refCategoryFixed: any[];
  stc = true;
  count: number;
  daysWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  hoursToday: string;
  cartCount: string;
  dateNow: Date;

  deliveryCharge: any;
  deliveryEstimation: any;
  deliveryAddress: any;
  deliveryFee: any;
  distanceInfo: any;

  open: boolean;
  closeText: string;
  closeHour: string;

  visit: boolean;

  whatsapp: string;
  msgWhats: string;

  itemPromo: any;
  photos = {};
  currentSection = '';

  isSticky: boolean;
  offsetTag: number;
  isMobile: boolean;

  token: string;

  @ViewChild('loading')  loading: boolean;

  constructor(
    private service: ApiService,
    private rat: NgbRatingConfig,
    private ngbModalService: NgbModal,
    public modalService: ModalService,
    public functions: FunctionsService,
    public cartService: CartService,
    private serviceModal: ModalService,
    private menuService: MenuService,
    private routeActive?: ActivatedRoute
  )
  {
    this.functions.setItemLocal('step', '');
    this.merchant = new Merchant();
    rat.max = 5;
    rat.readonly = true;
    this.dateNow = new Date();
    this.itemPromo = [];
    this.category = [];
    this.passId = '';
  }


  ngOnInit(): void
  {
    this.offsetTag = 0;
    this.addressEvent$ = AddressService.addressEmitter;
    this.addressEvent$.subscribe(data => {
      this.functions.log('Evento troca endereço');
      if (data)
      {
        this.distanceInfo  = this.functions.getItemLocal('distanceInfo') ? JSON.parse(this.functions.getItemLocal('distanceInfo')) : '';
        this.deliveryCharge = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;
        this.deliveryEstimation = this.functions.getItemLocal('deliveryEstimation') ? this.functions.getItemLocal('deliveryEstimation') : false;
        this.deliveryFee = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;
        console.log(this.deliveryFee);
      }
    });
    PageScrollConfig.defaultScrollOffset = 50;
    PageScrollConfig.defaultDuration = 700;
    this.msgWhats = 'Olá, estou no APP de vocês e gostaria de informações';
    this.loadPaymentCardList();
    this.loadService();
    this.getPagesGeneric();

    this.token = this.routeActive.snapshot.params[`token`];
    if (this.token)
    {
      this.service.changeToken(this.token);
      console.log(this.token);
    }

  }
  @HostListener('window:scroll', ['$event'])
  checkScroll(): void
  {
    this.isSticky = window.pageYOffset >= 200 +  this.offsetTag;
  }

  @HostListener('window:resize', ['$event'])
  resizeScreen(event): void
  {
    /*console.log(window.innerWidth);*/
    this.isMobile = window.innerWidth < 730;
  }

  ngAfterViewInit(): void
  {
    this.offsetTag = this.tagMenu.nativeElement.offsetTop;
  }

  showPageCustom(identify): void
  {
    this.functions.redirectPageCustom(identify);
    this.ngbModalService.dismissAll();
  }


  onSectionChange(sectionId: string): void
  {
    this.currentSection = sectionId;
    const groupCat = document.querySelector(`#category_header`) as HTMLElement;
    const cat = document.querySelector(`#category_header .${sectionId}`) as HTMLElement;
   /* if (this.isMobile)
    {
      cat.scrollIntoView();
    }*/
  }

  scrollPage(element: string, offset: number = 0): void
  {
    this.currentSection = '';
    const yOffset = -offset;
    const e = document.querySelector('#' + element).getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: e, behavior: 'smooth'});
    /*document.querySelector(element).scrollIntoView({behavior: 'smooth'});*/
  }

  getPagesGeneric(): void
  {
    this.pagesGeneric$ = this.service.get('getPagesGenericAll');
    this.pagesGeneric$.subscribe(data => {
      if (data.code === 1)
      {
        this.pagesGeneric = data.details.data;
      }
    });
  }

  asIsOrder(a, b): number
  {
    return 1;
  }

  verifyProductschedule(v: any): boolean
  {
    const today = new Date();
    const dateNow = this.functions.getDate() + ' ' + this.functions.getDate(true);
    const nowTime = new Date(dateNow).getTime();
    const initTime = new Date(this.functions.getDate() + ' ' + v.prog_init_hour).getTime();
    const endTime = new Date(this.functions.getDate() + ' ' + v.prog_end_hour).getTime();
    if (v.prog_day)
    {
      if (v.prog_day.includes(today.getDay()) && (v.prog_init_hour !== '00:00:00' || v.prog_end_hour !== '00:00:00'))
      {
        return (Number(nowTime) > Number(initTime) && Number(nowTime) < Number(endTime));
      }
      else if (v.prog_day.includes(today.getDay()) && (v.prog_init_hour === '00:00:00' && v.prog_end_hour === '00:00:00'))
      {
        return true;
      }
    }
    else if (v.prog_init_hour !== '00:00:00' || v.prog_end_hour !== '00:00:00')
    {
      return (Number(nowTime) > Number(initTime) && Number(nowTime) < Number(endTime));
    }
    else
    {
      return true;
    }
  }

  loadService(): void
  {
    if (this.functions.getItemLocal('merchantInfo'))
    {
      this.merchant = JSON.parse(this.functions.getItemLocal('merchantInfo'));
      this.locationMerchant = {lat: Number(this.merchant.latitude), lng: Number(this.merchant.longitude)};
      this.ratings = parseFloat(this.merchant.ratings.ratings);
      this.deliveryCharge = this.functions.getItemLocal('deliveryCharge') ? this.functions.getItemLocal('deliveryCharge') : false;
      this.deliveryEstimation = this.merchant.deliveryEstimation;
      this.verifyHoursMerchant(this.merchant.openingHours);

      if (this.merchant.whatsapp === '1')
      {
        this.whatsapp = `https://api.whatsapp.com/send?phone=+55${this.merchant.contactPhone}&text=${this.msgWhats}`;
      }
      else
      {
        this.whatsapp = this.functions.sanitizerLink('javascript:;');
      }
      /*console.log(typeof this.merchant.services);*/
    }



    this.category = [];
    this.category$ = this.service.getCategoryItens();
    this.category$.subscribe(data => {
      this.apiResponse = data;
      this.category = this.apiResponse.details.data;
      console.log(this.apiResponse);
      /*console.log(this.category);*/
      this.category = this.category.filter( (a, b) => {
        if (a.items)
        {
          return a;
        }
      });

      for (const [key, value] of Object.entries(this.category))
      {
        this.category[key].count = value.items.length;
        this.category[key].items =  this.category[key].items.filter((a: any, b: any) => {
          if (this.verifyProductschedule(a))
          {
            return a;
          }
        });

        for (const [k, v] of Object.entries(value.items ? value.items : ''))
        {
          this.photos[value.items[k].item_id] = value.items[k].photo;
          if (v.prices && v.prices[0].discount_price)
          {
            this.itemPromo.push(v);
          }
        }
      }

      /*console.log(this.category);*/
      this.category = this.category.filter( (a, b) => {
        a.items.filter((c: any, d: any) => {
          if (this.verifyProductschedule(c))
          {
            return c;
          }
        });
        if (a.items.length)
        {
          return a;
        }
      });

      /*console.log(this.category);*/

      this.functions.setItemLocal('photos_items', JSON.stringify(this.photos));
      }, error => {},
      () => {
          this.refCategoryFixed = this.refCategory;

      });
  }

  checkAllItemDate(): void
  {
    for (const [iCat, cat] of Object.entries(this.category))
    {
      if (cat && cat.items)
      {
        for (const [iItem, item] of Object.entries(cat.items))
        {
          if (this.verifyItemDate(item))
          {
            delete this.category[iCat].items[iItem];
          }
        }
        this.category[iCat].items.filter((value) => {
          return value != null;
        });
      }
    }
  }

  verifyItemDate(item): boolean
  {
    const currentDate = new Date();
    let response = true;
    const startDate = new Date(currentDate.getTime());
    const endDate = new Date(currentDate.getTime());


    startDate.setHours(item.prog_init_hour.split(':')[0]);
    startDate.setMinutes(item.prog_init_hour.split(':')[1]);
    startDate.setSeconds(item.prog_init_hour.split(':')[2]);

    endDate.setHours(item.prog_end_hour.split(':')[0]);
    endDate.setMinutes(item.prog_end_hour.split(':')[1]);
    endDate.setSeconds(item.prog_end_hour.split(':')[2]);

    item.prog_day = item.prog_day !== '' ? JSON.parse(item.prog_day) : null;
    this.functions.log(item.prog_day);
    if (item.prog_day && item.prog_day.length)
    {

      /*this.functions.log('esta no dia? ' + item.prog_day.includes(currentDate.getDay().toString()));
      this.functions.log('esta no horario? ' + (startDate < currentDate && endDate > currentDate));
      this.functions.log(item.prog_day);*/
      if (item.prog_day.includes(currentDate.getDay().toString()))
      {
        if (item.prog_init_hour !== item.prog_end_hour)
        {
          if (startDate < currentDate && endDate > currentDate)
          {
            /*this.functions.log('horario e dia');*/
            response = false;
          }
          else
          {
            response = true;
          }
        }
        else
        {
          response = false;
        }

        /*console.log('dia');
        response = false;
        console.log('response');
        console.log(response);*/
      }
      /*console.log('result hour');
      console.log(currentDate);
      console.log(startDate);
      console.log(endDate);
      console.log();*/
    }
   /* console.log(item.prog_day);
    console.log(currentDate.getDay());
    console.log(response);*/
    return response;
  }



  loadPaymentCardList(): void
  {
    this.service$ = this.service.get('getPayondeliverycards');
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.cardList = data.details.data;
      }
    });
  }

  openEdititem(catId: string, itemId: string): void
  {
    this.menuService.catId = catId;
    this.menuService.itemId = itemId;
    this.serviceModal.openItemModal();
  }

  openModal(modal): void
  {
    this.ngbModalService.open(modal);
  }

  openModalInfoMerchant(): void
  {
    this.modalService.openInfoMerchant();
  }

  closeModal(): void
  {
    this.ngbModalService.dismissAll();
  }

  convertNumber(arg: string): number
  {
    return Number(arg);
  }

  verifyHoursMerchant(hours): void
  {
    const dateTime = new Date();
    const daysOfWeek = {
      monday: 'Segunda-Feira',
      tuesday: 'Terça-Feira',
      wednesday: 'Quarta-Feira',
      thursday: 'Quinta-Feira',
      friday: 'Sexta-Feira',
      saturday: 'Sábado',
      sunday: 'Domingo'
    };

    let dateToday = Number(dateTime.getMonth()) < 10
        ? '0' + (Number(dateTime.getMonth()) + 1) + '/'
        : Number(dateTime.getMonth()) + 1 + '/';
    dateToday += Number(dateTime.getDate()) < 9 ? '0' + dateTime.getDate() + '/' : dateTime.getDate() + '/';
    dateToday += dateTime.getFullYear();

    const dateStart = `${dateToday} ${hours.start_time}:00`;
    const dateEnd = `${dateToday} ${hours.end_time}:00`;


    if (hours?.day === dateTime.toLocaleDateString('en', { weekday: 'long' }).toLowerCase())
    {
      if (new Date() > new Date(dateStart) && new Date() < new Date(dateEnd))
      {
        this.open = true;
        this.hoursToday = `${hours.start_time} - ${hours.end_time}`;
      }
      else if (new Date() < new Date(dateStart))
      {
        this.closeText = 'Abrirá hoje';
        this.closeHour = `${hours.start_time} - ${hours.end_time}`;
      }
      else
      {
        this.closeText = `Abrirá na próxima ${daysOfWeek[hours.day]}`;
        this.closeHour = `${hours.start_time} - ${hours.end_time}`;
        this.open = false;
      }
    }
    else
    {
      this.closeText = `Abrirá ${daysOfWeek[hours.day]}`;
      this.closeHour = `${hours.start_time} - ${hours.end_time}`;
      this.open = false;
    }

    this.functions.setItemLocal('open', this.open ? '777' : '0');
  }

  getHoursMerchant(hoursMerchant): void
  {
    if (Object.entries(hoursMerchant).length)
    {
      for (const [k, v] of Object.entries(hoursMerchant))
      {
        // @ts-ignore
        if (v.day === this.daysWeek[this.dateNow.getDay()])
        {
          const hourNow = `${('0' + this.dateNow.getHours()).slice(-2)}:${String(this.dateNow.getMinutes()).padStart(2, '0')}`;
          // @ts-ignore
          if (v.hours)
          {
            // @ts-ignore
            const tmp = v.hours.split('/');
            const am = tmp[0] ? tmp[0] : '';
            const pm = tmp[1] ? tmp[1] : '';
            if (am.split('-')[0].trim() <= hourNow && am.split('-')[1].trim() > hourNow)
            {
              this.open = true;
              this.hoursToday = am;
              break;
            }
            else if (am.split('-')[0].trim() > hourNow)
            {
              this.open = false;
              this.closeText = 'ABRIRÁ HOJE';
              this.closeHour = am;
              break;
            }
            else
            {
              if (pm)
              {
                this.functions.log(pm.split('-')[0].trim() < hourNow );
                if (pm.split('-')[0].trim() <= hourNow && pm.split('-')[1].trim() > hourNow)
                {
                  this.functions.log('Aberto Segundo Horario');
                  this.open = true;
                  this.hoursToday = pm;
                  break;
                }
                else
                {
                  this.closeText = 'Abrirá hoje';
                  this.closeHour = pm;
                  break;
                }
              }
              else
              {
                this.closeText = 'Abrirá ' + hoursMerchant[Number(k) + 1].day;
                this.closeHour = hoursMerchant[Number(k) + 1].hours;
                this.open = false;
                break;
              }
            }
            this.closeText = 'Abrirá ' + hoursMerchant[Number(k) + 1].day;
            this.closeInfo(hoursMerchant, k);
            this.open = false;
          }
          else
          {
            this.closeText = 'Abrirá ' + hoursMerchant[Number(k) + 1].day;
            this.closeInfo(hoursMerchant, k);
            this.open = false;
            break;
          }
        }
        else
        {
          this.open = false;
          this.closeInfo(hoursMerchant, k);
        }
      }
    }
    else
    {
      this.open = false;
      this.closeText = `Entre em contato com o estabelecimento`;
      this.closeHour = `Não existe horários cadastrados`;
    }

    this.service$ = this.service.getMerchantCloseStore();
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        this.open = false;
        this.closeText = 'Estabelecimento fechado';
        this.functions.setItemLocal('open', this.open ? '777' : '0');
        if (!this.open)
        {
          this.functions.setItemLocal('txtClose', JSON.stringify({closeText: this.closeText, closeHours: this.closeHour}));
        }
      }
      else
      {
        this.functions.setItemLocal('open', this.open ? '777' : '0');
        if (!this.open)
        {
          this.functions.setItemLocal('txtClose', JSON.stringify({closeText: this.closeText, closeHours: this.closeHour}));
        }
      }
    });

  }

  getHoursMerchantBackup(hoursMerchant): void
  {
    let hourInit = '';
    let hourClose = '';
    for (const [key, value] of Object.entries(hoursMerchant))
    {
      this.merchant.openingHours[key].hours = this.merchant.openingHours[key].hours.split('&')[0];
      // @ts-ignore
      if (value.day === this.daysWeek[this.dateNow.getDay()])
      {
        const hourNow = `${('0' + this.dateNow.getHours()).slice(-2)}:${String(this.dateNow.getMinutes()).padStart(2, '0')}`;
        const h: any = value;

        if (h.hours)
        {
          this.hoursToday = h.hours.split('&')[0];
          hourInit = this.hoursToday.split('-')[0].trim();
          hourClose = this.hoursToday.split('-')[1].trim();
          if (hourNow > hourInit && hourNow < hourClose)
          {
            this.open = true;
          }
          else
          {
            this.open = false;
            if (hourNow < hourInit)
            {
              this.closeText = 'Abrirá Hoje';
              this.closeHour = this.hoursToday;
            }
            else if (hourNow >= hourClose)
            {
              this.closeText = 'Abrirá ';
              this.closeInfo(hoursMerchant, key);
            }
          }

        }
        else
        {
          this.closeText = 'Abrirá ';
          this.closeInfo(hoursMerchant, key);
        }
      }
    }
  }

  closeInfo(hoursMerchant, key): void
  {
    console.log('close info');
    for (const [i, h] of Object.entries(hoursMerchant))
    {
      if (key === i && hoursMerchant[i].hours)
      {
        this.functions.log('IF horario');
        this.closeText += `Abrirá ${hoursMerchant[i].day}`;
        this.closeHour = `${hoursMerchant[i].hours.split('/').join(' ')}`;
        break;
      }
      else if (i > key && hoursMerchant[i].hours)
      {
        let firstDay = true;
        this.functions.log('ELSE horario');
        console.log('verifcação de chave maior');
        this.functions.log(hoursMerchant[i].hours);
        this.closeText = `Abrirá ${hoursMerchant[i].day}`;
        this.closeHour = `${hoursMerchant[i].hours.split('/').join(' ')}`;
        firstDay = false;
        break;
      }
      else
      {
        for (const [q, t] of Object.entries(hoursMerchant))
        {
          console.log(t);
          if (t[`hours`])
          {
            this.closeText = `Abrirá ${t[`day`]}`;
            console.log('verificando se existe horario');
            break;
          }
          break;
        }
      }
    }
  }


  showRoute(modal): void
  {
    this.ngbModalService.open(modal);
    this.initMap();
  }

  initMap(): void
  {
    const directionService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    const map = new google.maps.Map(document.getElementById('map2') as HTMLElement,
      {
        zoom: 10,
        center: {lat: Number(this.merchant.latitude), lng: Number(this.merchant.longitude)}
      }
    );

    directionsRenderer.setMap(map);

    this.calculateAndDisplayRoute(directionService, directionsRenderer);
  }

  calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer): void
  {
    let addressInit = '';
    let tmpAddress: any = '';

    if (this.functions.getItemLocal('deliveryAddress'))
    {
      tmpAddress = this.functions.getItemLocal('deliveryAddress') ? JSON.parse(this.functions.getItemLocal('deliveryAddress')) : '';
      if (tmpAddress)
      {
        addressInit = `${tmpAddress.street}, ${tmpAddress.number} - ${tmpAddress.bairro} - ${tmpAddress.city} - ${tmpAddress.state}`;
      }
    }
    const waypts: google.maps.DirectionsWaypoint[] = [];

    directionsService.route({
      origin: addressInit,
      waypoints: waypts,
      optimizeWaypoints: true,
      destination: this.merchant.address,
      travelMode: google.maps.TravelMode.DRIVING
    }, (response, status) => {
      if (status === 'OK')
      {
        directionsRenderer.setDirections(response);
      }
    });

  }





}
