import {Component, OnInit, ViewChild} from '@angular/core';
import {Merchant} from '../../class/merchant';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {Address} from '../../class/address';
import {UserProfile} from '../../class/user';
import {CartData, CartDetails, Details} from '../../class/cart';
import {FunctionsService} from '../../services/functions.service';
import {ApiService} from '../../services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CheckoutService} from '../../services/checkout.service';
import {CartService} from '../../services/new/cart.service';
import {CartService as CartService2} from '../../services/cart.service';
import {AddressService} from '../../services/address.service';
import {ModalService} from '../../services/new/modal.service';

@Component({
  selector: 'app-cart-payment',
  templateUrl: './cart-payment.component.html',
  styleUrls: ['./cart-payment.component.css']
})
export class CartPaymentComponent implements OnInit {


  @ViewChild('modalLogin', { static: false }) private modalLogin;
  @ViewChild('modalAddress', { static: false }) private modalAddress;

  merchantInfo: Merchant;
  cart$: Observable<ApiResponse>;

  cartService$: Observable<string>;
  addressService$: Observable<boolean>;

  address: Address;
  userProfile: UserProfile;
  addressList: Address[];
  merchant: Merchant;
  details: Details;
  cartDetails: CartDetails;
  cartData: CartData;
  photos: any[];
  transactionType = '';
  subItemHtml: any[];

  transactionUrl: string;

  addressDelivery: any;
  showAddress = false;

  open: any;

  visit: boolean;
  cartEmpty: boolean;

  step: any;
  txtBtn: string;

  payment: boolean;

  loadingCart: boolean;

  constructor(
      public functions: FunctionsService,
      private service: ApiService,
      private ngbModalService: NgbModal,
      public modalService: ModalService,
      public checkoutService: CheckoutService,
      public cartService: CartService)
  {
    this.payment = false;
    this.loadingCart = false;
    this.subItemHtml = [];
    const merchant = this.functions.getItemLocal('merchantInfo');
    this.merchantInfo =  merchant ? JSON.parse(merchant) : new Merchant();
    this.cartData = new CartData();
    this.cartData.item = [];
    this.cartDetails = new CartDetails();
    this.step = this.functions.getItemLocal('step');
  }

  ngOnInit(): void
  {
    this.cartService.loadCart();


    this.addressService$ = AddressService.addressEmitter;
    this.addressService$.subscribe(data => {
      this.functions.log('evento emitido');
      this.functions.log(data);
      if (data)
      {
        this.cartService.loadCart();
      }
    });


    if (this.step && this.step === 'payment')
    {
      this.txtBtn = 'Confirmar Pedido';
      this.payment = true;
    }
    else
    {
      this.txtBtn = 'Escolher pagamento';
    }
  }

  transactionTypeChange(e): void
  {
    const type = e.target.value;
    this.transactionType = type;
    this.showAddress = false;
    if (type === 'delivery')
    {
      if (this.functions.getItemLocal('deliveryAddress'))
      {
        this.addressDelivery = JSON.parse(this.functions.getItemLocal('deliveryAddress'));
        this.showAddress = true;
      }
      else
      {
        this.functions.showAlertError('Houve um problema com seu endereço!');
      }
    }

    this.functions.setItemLocal('transaction_type', type);

  }


  checkout(): void
  {
    if (!this.functions.isLogin())
    {
      return;
    }

    else if (!this.functions.getItemLocal('deliveryAddress'))
    {

      return;
    }

    else if (this.step && this.step === 'payment')
    {
      this.functions.log('Payment');
      this.functions.log('Finalizar a Venda');
      this.ngbModalService.dismissAll();
    }

    else
    {
      this.functions.redirect('payment');
      this.functions.setItemLocal('step', 'payment');
      this.ngbModalService.dismissAll();
    }
  }
}
