export class Pay
{
  transaction_type: string;
  payment_provider: string;
  delivery_date: string;
  delivery_time: string;
  order_change: string;
  delivery_asap: any;
  selected_card: string;
  payment_params: any;
  delivery_instruction: string;
  request_from: string;
  clientName: string;

  constructor()
  {
    this.transaction_type = '';
    this.payment_provider = '';
    this.payment_params = '';
    this.delivery_asap = '';
    this.delivery_date = '';
    this.delivery_instruction = '';
    this.delivery_time = '';
    this.request_from = '';
    this.order_change = '0';
    this.clientName = '';
  }

}
