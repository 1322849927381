import {Address} from './address';

export class User
{
  username: string;
  password: string;
}

export class UserProfile
{
  avatar: string;
  contact_phone: string;
  email_address: string;
  enabled_push: string;
  first_name: string;
  last_name: string;
  full_name: string;
  address: Address;
}

export class UserSignup
{
  first_name: string;
  last_name: string;
  email_address: string;
  contact_phone: string;
  password: string;
  cpassword: string;
}

export class PasswordForm
{
  current_password: string;
  password: string;
  cpassword: string;
}
