export class Order
{
  order_id: string;
  json_details: any;
  merchant_id: string;
  merchant_name: string;
  payment_type: string;
  placed: string;
  rating: any;
  show_cancel_order: any;
  status: string;
  status_raw: string;
  total: string;
  subtotal: string;
  transaction_type: string;
  add_review: any;
  cancel_status: string;
  logo: string;
  hour_create: string;
  date_create: string;
  count_cart: number;
  delivery_charge: string;
  delivery_instruction: string;
  voucher_code: string;
  voucher_amount: string;
  order_address: OrderAddress[];
  order_details: OrderDetails[];
  order_change:string;
  payment_provider_name: string;
  status_code: string;
}

export class OrderAddress
{
  street: string;
  number: string;
  bairro: string;
  complemento: string;
  city: string;
  state: string;
  zipcode: string;
  contact_phone: string;
  google_lat: string;
  google_lng: string;
}

export class OrderDetails
{
  item_id: string;
  addon: string;
  cooking_ref: string;
  discounted_price: string;
  ingredients: string;
  item_name: string;
  normal_price: string;
  order_notes: string;
  qty: string;
  size: string;
  photo: string;
}
