import { Injectable, EventEmitter } from '@angular/core';
import {AddressService} from './address.service';
import {CartService} from './cart.service';
import {FunctionsService} from './functions.service';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  static loginEmitter = new EventEmitter<string>();

  constructor(
      private functions: FunctionsService,
  ) { }

  actionLogin(): void
  {
    LoginService.loginEmitter.emit('login');
  }
  disabledButton(): void
  {
    LoginService.loginEmitter.emit('loginDisabledBtn');
  }

  openRegister(): void
  {
    LoginService.loginEmitter.emit('openRegister');
  }

  openLogin(): void
  {
    LoginService.loginEmitter.emit('openLogin');
  }

  openForgotPass(): void
  {
    LoginService.loginEmitter.emit('openForgotPass');
  }

  logout(): any
  {
    this.functions.removeItemLocal('cartCount');
    this.functions.removeItemLocal('userProfile');
    this.functions.removeItemLocal('token');
    this.functions.removeItemLocal('distanceInfo');
    this.functions.removeItemLocal('deliveryCharge');
    this.functions.removeItemLocal('tmpPayment');
    this.functions.removeItemLocal('orderReview');
    this.functions.removeItemLocal('passId');
    LoginService.loginEmitter.emit('logout');
    AddressService.addressEmitter.emit(true);
    AddressService.showInfoMerchant = true;
    CartService.emitterAddCart.emit('updateCart');
    this.functions.redirect('home');
  }
}
