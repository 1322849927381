import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceAll'
})
export class ReplaceAllPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const re = /[\s+?]/gi;
    return value.replace(re, '');
  }

}
