import {Injectable, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {CartData, CartDetails, Details} from '../../class/cart';
import {FunctionsService} from '../functions.service';
import {ApiService} from '../api.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  apiResponse$: Observable<ApiResponse>;
  cart$: Observable<ApiResponse>;

  details: Details;
  cartDetails: CartDetails;
  cartData: CartData;
  photos: any[];
  transactionType = '';
  subItemHtml: any[];
  deliveryFee: any;
  deliveryEstimation: any;

  addressDelivery: any;

  showAddress = false;
  cartEmpty: boolean;
  loading: boolean;

  cartCount: number;

  hiddenService: any;


  constructor(
      private functions: FunctionsService,
      private service: ApiService
  )
  {
    this.cartCount = 0;
    this.loading = false;
    this.deliveryFee = false;
    this.deliveryEstimation = false;
    this.cartData = new CartData();
    this.cartDetails = new CartDetails();
    this.details = new Details();
    this.hiddenService = {
      delivery: false,
      pickup: false
    };
  }

  loadCart(): void
  {
    /*console.log('Função load cart');*/
    this.cartData = new CartData();
    this.cartDetails = new CartDetails();
    this.details = new Details();
    this.photos = [];
    this.subItemHtml = [];
    this.loading = false;
    this.transactionType = '';
    if (this.functions.getItemLocal('transaction_type'))
    {
      this.transactionType = this.functions.getItemLocal('transaction_type');
    }
    console.log(this.transactionType);
    this.cart$ = this.service.loadCart(this.transactionType);
    this.loading = true;
    this.cart$.subscribe(data => {
      console.log(data);
      this.deliveryFee = false;
      if (data.code === 1)
      {
        this.cartEmpty = false;
        this.details = data.details;
        this.cartDetails = this.details.cart_details;
        this.cartData = this.details.data;
        this.transactionType = data.details.transaction_type;
        console.log(data.details.transaction_type);
        let totalCart = 0;
        let addonPrice = 0;

        if (this.cartData.item !== undefined)
        {
          totalCart = 0;
          this.cartCount = 0;

          for (const [k, c] of Object.entries(this.cartData.item))
          {
            const valuesCalc = {};
            let totalAverage = 0;
            let totalHigher = 0;
            this.cartCount += Number(c.qty);
            addonPrice = 0;
            let text = '';
            if (c.sub_item)
            {

              for (const d of c.sub_item)
              {
                text += d.addon_qty > 0 ?  d.addon_qty + ' X ' : '1 X ';
                text += d.addon_name + ' ';

                if (d.addon_calculate_type !== '')
                {
                  if (!valuesCalc[d.addon_calculate_type])
                  {
                    valuesCalc[d.addon_calculate_type] = {};
                  }
                  if (!Array.isArray(valuesCalc[d.addon_calculate_type][d.addon_calculate_type_cat_id]))
                  {
                    valuesCalc[d.addon_calculate_type][d.addon_calculate_type_cat_id] = [];
                  }
                  valuesCalc[d.addon_calculate_type][d.addon_calculate_type_cat_id].push(Number(d.addon_price));
                  text += ' <b>•</b> ';
                }
                else
                {
                  text += d.addon_price && d.addon_price > 0 ? this.functions.prettyPrice(d.addon_price) : ' <b>•</b> ';
                  if (d.addon_price && d.addon_price > 0)
                  {
                    if (d.addon_qty > 0)
                    {
                      addonPrice += Number(d.addon_price) * Number(d.addon_qty );
                      // tslint:disable-next-line:max-line-length
                      text += d.addon_price && d.addon_price > 0 && d.addon_qty > 1  ? ' (<b>' + this.functions.prettyPrice(Number(d.addon_price) * Number(d.addon_qty)) + '</b>) <b>•</b> ' : ' <b>•</b> ';
                    }
                    else
                    {
                      addonPrice += Number(d.addon_price);
                      text += ', ';
                    }
                  }
                }
              }
              if (valuesCalc[`higher`] != null)
              {
                for (const [index, higher] of Object.entries(valuesCalc[`higher`]))
                {
                  if (Array.isArray(higher))
                  {
                    totalHigher = higher.reduce((a, b) => {
                      return  Math.max(a, b);
                    }, -Infinity);
                  }
                }
              }

              if (valuesCalc[`average`] != null)
              {
                for (const [index, average] of Object.entries(valuesCalc[`average`]))
                {
                  if (Array.isArray(average))
                  {
                    const sum = average.reduce((partialSum, a) => partialSum + a, 0);
                    totalAverage = Number(sum) / Number(average.length);
                  }
                }
              }

              this.subItemHtml.push(text.slice(0, -2));
            }
            else
            {
              this.subItemHtml.push('');
            }

            this.cartData.item[k].discounted_price = Number(c.discounted_price) + addonPrice + totalAverage + totalHigher;
            if (totalAverage || totalHigher)
            {
              this.cartData.item[k].normal_price = this.cartData.item[k].discounted_price;
            }
            /*if (!this.cartData.item[k].normal_price)
            {
              totalCart += Number(addonPrice);
            }
            else
            {
              totalCart += Number(this.cartData.item[k].normal_price);
            }*/

          }
          if (this.cartData.total.less_voucher)
          {
            this.cartDetails.voucher_details = JSON.parse(this.cartDetails.voucher_details);
            // this.cartData.total.total = Number(this.cartData.total.total) - Number(this.cartData.total.less_voucher);
          }

          if (this.cartDetails.delivery_fee || this.cartDetails.street)
          {
            /*if (Number(this.cartDetails.delivery_fee) !== Number(this.cartData.total.delivery_charges))
            {
              this.cartDetails.delivery_fee = this.cartData.total.delivery_charges;
            }*/

            this.deliveryFee = this.cartDetails.delivery_fee;
          }
          else
          {
            this.deliveryFee = false;
            this.deliveryEstimation = false;
          }
        }
        this.getPhotoItems();
      }
      else
      {
        this.cartEmpty = true;
      }
    }, error => {this.functions.log(error); },
    () => {
      this.getHoursDelivery();
      this.getHoursPickup();
      if (this.hiddenService.pickup || this.hiddenService.delivery)
      {
        if (this.hiddenService.delivery)
        {
          this.transactionType = 'pickup';
        }
        else if (this.hiddenService.pickup)
        {
          this.transactionType = 'delivery';
        }
        else
        {
          this.transactionType = '';
        }
      }

      console.log('set transaction');
      console.log(this.transactionType);
      this.functions.setItemLocal('transaction_type', this.transactionType);
      this.loading = false;
      if (this.cartCount === 0)
      {
        this.functions.redirect('home');
      }
      if (this.transactionType === 'delivery')
      {
        if (this.functions.getItemLocal('deliveryAddress'))
        {
          this.addressDelivery = JSON.parse(this.functions.getItemLocal('deliveryAddress'));

          if (this.addressDelivery)
          {
            this.apiResponse$ = this.service.setDeliveryAddress(this.addressDelivery);
            this.apiResponse$.subscribe(address => {
              if (address.code === 1)
              {
                this.showAddress = true;
              }
            }, error => {

            }, () => {
              this.loading = false;
            });
          }
          else
          {
            this.showAddress = true;
            this.loading = false;
          }
        }
        else
        {
          this.loading = false;
        }
      }
    }
    );
  }

  getPhotoItems(): void
  {
    if (this.functions.getItemLocal('photos_items'))
    {
      this.photos = JSON.parse(this.functions.getItemLocal('photos_items'));
    }

    // tslint:disable-next-line:forin
    for (const c in this.cartData.item)
    {
      for (const d in this.photos)
      {
        if (this.cartData.item[c].item_id === d)
        {
          this.cartData.item[c].photo = this.photos[d];
          break;
        }
      }
    }
  }

  removeCartItem(row): void
  {
    this.apiResponse$ = this.service.removeItemCart(row);
    this.apiResponse$.subscribe(data => {
      if (data.code === 1)
      {
        this.functions.showAlertSuccess('Item removido do carrinho');
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    }, error => {
        this.functions.showAlertErroServer();
        this.functions.log(error);
    }, () => {
      this.loadCart();
    } );
  }

  cleanCart(): void
  {
    this.apiResponse$ = this.service.clearCart();
    this.apiResponse$.subscribe(data => {
      if (data.code === 1)
      {
        this.functions.showAlertSuccess(data.msg);
      }
      else
      {
        this.functions.showAlertError('ERRO');
      }
    }, error => {
      this.functions.showAlertErroServer();
      this.functions.log(error);
    }, () => {
      this.loadCart();
    } );
  }

  getNormalPrice(row: string, qty: any = 0, unitario: boolean = false): string
  {
    let price = 0;
    let addonPrice = 0;
    if (this.cartData.item[row].discount)
    {
      price = Number(this.cartData.item[row].normal_price) - Number(this.cartData.item[row].discount);
    }
    else if (this.cartData.item[row].normal_price)
    {
      price = Number(this.cartData.item[row].normal_price);
    }

    if (this.cartData.item[row].discounted_price)
    {
      if (price > 0)
      {
        addonPrice = Number(this.cartData.item[row].discounted_price) - Number(price);
        addonPrice = Number(addonPrice) / Number(qty);
      }
      else
      {
        price  = Number(this.cartData.item[row].discounted_price) / Number(qty);
      }
    }

    if (Number(qty))
    {
      price = (Number(price) + Number(addonPrice)) * Number(qty);
    }

    if (unitario)
    {
      price = price / Number(qty);
    }

    return price.toString();
  }

  verifyHours(hours, type): any
  {
    let open = false;
    let hoursReturn = '';
    let textReturn = '';
    const dateTime = new Date();
    const daysOfWeek = {
      monday: 'Segunda-Feira',
      tuesday: 'Terça-Feira',
      wednesday: 'Quarta-Feira',
      thursday: 'Quinta-Feira',
      friday: 'Sexta-Feira',
      saturday: 'Sábado',
      sunday: 'Domingo'
    };

    let dateToday = Number(dateTime.getMonth()) < 10
        ? '0' + (Number(dateTime.getMonth()) + 1) + '/'
        : Number(dateTime.getMonth()) + 1 + '/';
    dateToday += Number(dateTime.getDate()) < 9 ? '0' + dateTime.getDate() + '/' : dateTime.getDate() + '/';
    dateToday += dateTime.getFullYear();

    const dateStart = `${dateToday} ${hours.start_time}:00`;
    const dateEnd = `${dateToday} ${hours.end_time}:00`;


    if (hours?.day === dateTime.toLocaleDateString('en', { weekday: 'long' }).toLowerCase())
    {
      if (new Date() > new Date(dateStart) && new Date() < new Date(dateEnd))
      {
        open = true;
        textReturn = `${hours.start_time} - ${hours.end_time}`;
      }
      else if (new Date() < new Date(dateStart))
      {
        textReturn = `hoje `;
        hoursReturn = `${hours.start_time} - ${hours.end_time}`;
      }
      else
      {
        textReturn = `na próxima ${daysOfWeek[hours.day]} `;
        hoursReturn = `${hours.start_time} - ${hours.end_time}`;
        open = false;
      }
    }
    else
    {
      textReturn = `${daysOfWeek[hours.day]} `;
      hoursReturn = `${hours.start_time} - ${hours.end_time}`;
      open = false;
    }

    return {
      open,
      text: textReturn,
      hours: hoursReturn
    };
  }

  getHoursDelivery(): void
  {
    this.apiResponse$ = this.service.getHoursDelivery();
    this.apiResponse$.subscribe(data => {
      if (data.code === 1)
      {
        const result = this.verifyHours(data.details, 'delivery');
        this.hiddenService.delivery = !result.open;
        this.hiddenService.deliveryMsg = result.text;
        this.hiddenService.deliveryHours = result.hours;
      }
    });
  }

  getHoursPickup(): void
  {
    this.apiResponse$ = this.service.getHoursPickup();
    this.apiResponse$.subscribe(data => {
      if (data.code === 1)
      {
        const result = this.verifyHours(data.details, 'pickup');
        this.hiddenService.pickup = !result.open;
        this.hiddenService.pickupMsg = result.text;
        this.hiddenService.pickupHours = result.hours;
      }
    });
  }

}
