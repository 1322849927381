<!--Scroll to top-->
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <button class="btn btn-secondary px-2 py-1" pageScroll pageScrollOffset="0" href="#__topHome">
        <i class="feather-arrow-up h5"></i>
    </button>

</div>
<div class="button-bottom-top text-center">
    <button class="btn btn-outline-secondary small" pageScroll pageScrollOffset="0" href="#__topHome">Voltar para o topo</button>
</div>
