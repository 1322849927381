import { Injectable } from '@angular/core';
import {Pay} from '../class/pay';
import {CartDetails, Details} from '../class/cart';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {ApiService} from './api.service';
import {FunctionsService} from './functions.service';
import {CartService} from './new/cart.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  private service$: Observable<ApiResponse>;

  protected addressError: boolean;
  protected paymentError: boolean;

  public payForm: Pay;

  public clickCancel: boolean;



  constructor(
      private service: ApiService,
      private functions: FunctionsService,
      private cartService: CartService
  )
  {
    this.clickCancel = false;
  }

  public changeAddressCart(address: any): Observable<ApiResponse>
  {
    return this.service.changeAddressCart(address);
  }


  validPayNow(): void
  {
    if (this.functions.getItemLocal('open') && this.functions.getItemLocal('open') === '0')
    {
      this.functions.showAlertError('Estabelecimento Fechado', 'Não é possivel realizar pedidos');
      return;
    }
    this.clickCancel = true;
    this.addressError = false;
    this.paymentError = false;

    const date = new Date();
    const dateNow = `${date.getFullYear()}-${date.getUTCMonth()}-${date.getDate()}`;
    const hourNow = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    this.payForm.delivery_date = dateNow;
    this.payForm.delivery_time = hourNow;
    this.payForm.delivery_asap = false;

    this.functions.log(this.cartService.details.transaction_type);
    // DELIVERY VALIDATION
    /*if (!this.payForm.payment_provider)
    {
      this.paymentError = true;
      this.clickCancel = false;
      this.functions.log(document.getElementById('__payment_form'));
      document.getElementById('__payment_form').classList.add('error');
      this.functions.showAlertError('Erro pagamento', 'Selecione uma forma de pagamento!');
      return;
    }*/

    this.payForm.request_from = 'Catálogo App';


    this.service$ = this.service.payNow(this.payForm);
    this.service$.subscribe(data => {
      if (data.code === 1)
      {
        console.log(data);
        this.functions.showAlertSuccess(data.msg);
        this.service.loadCountOrder();
        this.cartService.cartCount = 0;
        this.functions.redirect('aguardando-pedido', data.details.order_id);
      }
      else
      {
        this.functions.showAlertError(data.msg);
      }
    }, error => {
      this.functions.showAlertError('Erro ao conectar ao servidor, tente novamente!');
      this.functions.log(error);
    }, () => {
      this.clickCancel = false;
    });
  }
}
