import {Component, OnInit, ViewChild} from '@angular/core';
import {Merchant} from '../../class/merchant';
import {Observable} from 'rxjs';
import {ApiResponse} from '../../class/apiResponse';
import {Address} from '../../class/address';
import {UserProfile} from '../../class/user';
import {CartData, CartDetails, Details} from '../../class/cart';
import {FunctionsService} from '../../services/functions.service';
import {ApiService} from '../../services/api.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CheckoutService} from '../../services/checkout.service';
import {CartService} from '../../services/new/cart.service';
import {CartService as Cartservice2} from '../../services/cart.service';
import {AddressService} from '../../services/address.service';
import {ModalService} from '../../services/new/modal.service';


@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.css']
})
export class CartModalComponent implements OnInit {

  @ViewChild('modalLogin', { static: false }) private modalLogin;
  @ViewChild('modalAddress', { static: false }) private modalAddress;

  merchantInfo: Merchant;
  apiResponse$: Observable<ApiResponse>;
  cart$: Observable<ApiResponse>;

  cartService$: Observable<string>;
  addressService$: Observable<boolean>;

  address: Address;
  userProfile: UserProfile;
  addressList: Address[];
  merchant: Merchant;
  details: Details;
  cartDetails: CartDetails;
  cartData: CartData;
  photos: any[];
  transactionType = '';
  subItemHtml: any[];

  transactionUrl: string;

  addressDelivery: any;
  showAddress = false;

  open: any;

  visit: boolean;
  cartEmpty: boolean;

  step: any;
  txtBtn: string;

  payment: boolean;


  constructor(
      public functions: FunctionsService,
      public activeModal: NgbActiveModal,
      private service: ApiService,
      public checkoutService: CheckoutService,
      public cartService: CartService,
      public modalService: ModalService)
  {
    this.payment = false;
    const merchant = this.functions.getItemLocal('merchantInfo');
    this.merchantInfo =  merchant ? JSON.parse(merchant) : new Merchant();
    this.step = this.functions.getItemLocal('step');
  }

  ngOnInit(): void
  {
    this.cartService.loadCart();
    this.addressService$ = AddressService.addressEmitter;
    this.addressService$.subscribe(data => {
      if (data)
      {
        this.cartService.loadCart();
      }
    });


    if (this.step && this.step === 'payment')
    {
      this.txtBtn = 'Confirmar Pedido';
      this.payment = true;
    }
    else
    {
      this.txtBtn = 'Escolher pagamento';
    }
  }

  transactionTypeChange(e): void
  {
    const type = e.target.value;
    this.transactionType = type;
    this.showAddress = false;
    if (type === 'delivery')
    {
      if (this.functions.getItemLocal('deliveryAddress'))
      {
        this.addressDelivery = JSON.parse(this.functions.getItemLocal('deliveryAddress'));
        this.showAddress = true;
      }
      else
      {
        this.functions.showAlertError('Houve um problema com seu endereço!');
      }
    }

    this.functions.setItemLocal('transaction_type', type);

  }


  checkout(): void
  {
    if (this.functions.getItemLocal('open') && this.functions.getItemLocal('open') === '0')
    {
      this.functions.showAlertError('Estabelecimento Fechado', 'Não é possivel realizar pedidos');
      return;
    }
    if (this.step && this.step === 'payment')
    {
      this.activeModal.close();
    }

    else
    {
      this.functions.redirect('payment');
      this.functions.setItemLocal('step', 'payment');
      this.activeModal.close();
    }
  }
}
