<header class="section-header" *ngIf="service.color$" id="__top">
    <section class="header-main shadow-sm bg-white fixed-top">
        <div class="container">
            <div class="row align-items-center py-2">
                <div class="col-1">
                    <a href="javascript:;" routerLink="/home" class="brand-wrap mb-0">
                        <img alt="{{merchant.merchantName}}" src="{{merchant.optionsMerchant?.logo_app}}">
                    </a>
                    <!-- brand-wrap.// -->
                </div>
                <div class="col-5 d-flex align-items-center sm-none">

                </div>
                <!-- col.// -->
                <div class="col-5">
                    <div class="d-flex align-items-center justify-content-end">
                        <!-- my account -->
                        <div *ngIf="isLogin" class="dropdown mr-4 sm-none">
                            <a href="#" class="dropdown-toggle text-dark py-3 d-block" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Olá {{user.first_name}},
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="javascript:;" (click)="functions.refresh('profile', 'order')">Meus Pedidos</a>
                                <a class="dropdown-item" href="javascript:;" (click)="functions.refresh('profile', 'account')">Minha Conta</a>
                                <a class="dropdown-item" href="javascript:;" (click)="functions.refresh('profile', 'address')">Meus endereços</a>
                                <a class="dropdown-item" href="javascript:;" (click)="logout()">Sair</a>
                            </div>
                        </div>
                        <!-- signin -->
                        <a href="javascript:;" (click)="openModalCart()" class="widget-header mr-4 text-dark">
                            <div class="icon d-flex align-items-center my-2">
                                <i class="feather-shopping-bag h6 mr-2 mb-0"></i>
                                <span *ngIf="cartService.cartCount > 0" class="badge-item-header position-absolute ml-2 text-white"><span class="small">{{cartService.cartCount}}</span></span>
                                <span>cesta</span>
                            </div>
                        </a>
                    </div>
                    <!-- widgets-wrap.// -->
                </div>
                <!-- col.// -->
            </div>
            <!-- row.// -->
        </div>
        <!-- container.// -->
    </section>
    <!-- header-main .// -->
</header>


<div class="d-none header-mobile">
    <div>
        <div class="header-mobile-logo">
            <a href="javascript:;" routerLink="/home">
                <img alt="{{merchant.merchantName}}" class="" src="{{merchant.optionsMerchant.logo_app}}">
            </a>
        </div>
        <div class="header-mobile-address">
            <p class="font-weight-bold">{{merchant.merchantName}}</p>
        </div>
    </div>
</div>


