export class DeliveryFee
{
  delivery_fee: string;
  distance: string;
  duration: number;
  duration_in_traffic: string;
  meters: number;
  pretty_distance: string;
  pretty_unit: string;
  provider: string;
  unit: string;
}

export class Payment
{
  payment_code: string;
  payment_name: string;
  img: string;
}

export class Cards
{
  id: string;
  payment_name: string;
  payment_logo: string;
}
