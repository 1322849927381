<div class=" mb-5 mb-sm-0" *ngIf="!cartService.loading">
    <div class="osahan-cart-item rounded rounded shadow-sm overflow-hidden bg-white sticky_sidebar zindex-2">
        <div class="bg-white px-3 pt-2">
            <h5 class="m-0">Resumo do pedido</h5>
        </div>
        <div class="bg-white border-bottom py-2">

            <div *ngFor="let item of cartService.cartData.item | keyvalue" class="d-flex list-card bg-white overflow-hidden position-relative px-3 pt-3 pb-1 mb-1 border-top">
                <div class="media menu-list-checkout d-flex">
                    <div class="media-body">
                        <p class="mb-1 cart-item-name">{{item.value.qty}} x <b>{{item.value.item_name}}</b></p>
                        <p class="price-card price-position text-black mb-0">
                            <small *ngIf="item.value.qty > 1" class="text-muted">
                                {{item.value.qty}} X <small>{{item.value.size_words}}</small> {{cartService.getNormalPrice(item.key, item.value.qty, true) | prettyPrice}} =
                            </small>
                            <span class="font-weight-bold">
                                <small *ngIf="item.value.qty === 1">{{item.value.size_words}}</small>
                                {{cartService.getNormalPrice(item.key, item.value.qty) | prettyPrice}}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="ml-auto btn-add">
                    <a class="mr-1 btn p-0 text-danger" href="javascript:;" (click)="cartService.removeCartItem(item.key)"><i class="feather-x h6 font-weight-bold"></i></a>
                    <a class="btn p-0" href="javascript:;" (click)="modalService.openEditItemModal(item.value.category_id, item.value.item_id, item.key)"> <i class="feather-edit h6 font-weight-bold"></i></a>
                    <!--- <a class="mr-1 btn p-0" href="javascript:;" (click)="modalService.openEditItemModal(item.value.category_id, item.value.item_id, item.key)"> <i class="feather-edit h6 font-weight-bold"></i></a>-->
                </div>
            </div>
        </div>

        <div class="bg-white p-3 clearfix border-bottom" *ngIf="cartService.cartData.total.total">
            <p class="mb-1">Sub Total <span class="float-right text-dark">{{functions.prettyPrice(cartService.cartData.total.subtotal)}} </span></p>

            <hr>
            <h6 class="font-weight-bold mb-0">TOTAL A PAGAR <span class="float-right">{{functions.prettyPrice(cartService.cartData.total.total)}}</span></h6>
        </div>
        <div class="p-3 btn-cart-lateral" *ngIf="cartService.cartData.total">
            <button
                    class="btn btn-success btn-block btn-lg"
                    type="button"
                    (click)="checkoutService.validPayNow()"
                    [disabled]="checkoutService.clickCancel"
            >
                <div class="spinner-border" role="status" *ngIf="checkoutService.clickCancel">
                    <span class="sr-only">Loading...</span>
                </div>
                {{txtBtn}} {{cartService.cartData.total.total | prettyPrice}}
                <i class="feather-arrow-right"></i>
            </button>
        </div>

        <div class="p-3 btn-cart-flutuante w-100 text-center" *ngIf="cartService.cartData.total.total">
            <button
                    class="btn btn-success btn-block btn-lg"
                    type="button"
                    (click)="checkoutService.validPayNow()"
                    [disabled]="checkoutService.clickCancel"
            >
                <div class="spinner-border" role="status" *ngIf="checkoutService.clickCancel">
                    <span class="sr-only">Loading...</span>
                </div>
                {{txtBtn}} {{cartService.cartData.total.total | prettyPrice}}
                <i class="feather-arrow-right"></i>
            </button>
        </div>
    </div>
</div>

<app-loading *ngIf="cartService.loading"></app-loading>
