import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MenuComponent} from '../../menu/menu.component';
import {MenuService} from '../../menu/menu.service';
import {InfoModalComponent} from '../../modals/info-modal/info-modal.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
      private modal: NgbModal,
      private menuService: MenuService,
  ) { }

  openItemModal(): void
  {
    this.modal.open(MenuComponent);
  }

  openEditItemModal(catId: string, itemId: string, row: string): void
  {
    this.menuService.openModalEdit(catId, itemId, row);
    this.modal.open(MenuComponent);
  }

  openInfoMerchant(): void
  {
    this.modal.open(InfoModalComponent);
  }

}
