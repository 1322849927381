import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FunctionsService} from '../services/functions.service';
import {ApiService} from '../services/api.service';
import {MapsService} from '../services/maps.service';

@Component({
  selector: 'app-network-erro',
  templateUrl: './network-erro.component.html',
  styleUrls: ['./network-erro.component.css']
})
export class NetworkErroComponent implements OnInit {

  constructor(
    private service: ApiService,
    private router: Router,
    private functions: FunctionsService,
    private mapService: MapsService,
  ) { }

  ngOnInit(): void {

  }


  verifyConnect(): void
  {
    if (navigator.onLine)
    {
      this.mapService.loadMap();
      this.service.loadDefaultColor();
    }
    else
    {
      this.functions.showAlertError('Ainda sem conexão!!!');
    }
  }
}
