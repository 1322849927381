

export class CartDetails
{
  street: string;
  number: string;
  bairro: string;
  complemento: string;
  reference: string;
  city: string;
  state: string;
  location_name: string;
  zipcode: string;
  cart_count: string;
  contact_phone: string;
  country_code: string;
  date_modified: string;
  delivery_fee: any;
  delivery_instruction: string;
  delivery_lat: string;
  delivery_long: string;
  device_platform: string;
  min_delivery_order: string;
  points_amount: string;
  points_apply: string;
  points_earn: string;
  tips: string;
  voucher_details: any;

  constructor()
  {
    this.street = '';
    this.number = '';
    this.bairro = '';
    this.city = '';
    this.complemento = '';
    this.reference = '';
    this.state = '';
    this.location_name = '';
    this.zipcode = '';
    this.cart_count = '';
    this.contact_phone = '';
    this.country_code = '';
    this.date_modified = '';
    this.delivery_fee = '';
    this.delivery_instruction = '';
    this.delivery_lat = '';
    this.delivery_long = '';
    this.device_platform = '';
    this.min_delivery_order = '';
    this.points_amount = '';
    this.points_apply = '';
    this.points_earn = '';
    this.tips = '';
    this.voucher_details = '';
  }
}

export class Details
{
  available_points: number;
  available_points_label: string;
  cart_details: any;
  cart_error: any;
  checkout_stats: any;
  data: any;
  default_delivery_date: string;
  default_delivery_date_pretty: string;
  has_addressbook: number;
  is_apply_tax: number;
  points_earn: string;
  points_enabled: string;
  pts_disabled_redeem: string;
  required_delivery_time: string;
  services: any;
  tip_list: any;
  transaction_type: string;
}

export class Item
{
  category_id: string;
  category_name: string;
  category_name_trans: any;
  cooking_name_trans: string;
  cooking_ref: string;
  discount: string;
  discounted_price: number;
  ingredients: string;
  item_id: string;
  item_name: string;
  item_name_trans: any;
  non_taxable: number;
  normal_price: string;
  order_notes: string;
  qty: string;
  size_name_trans: string;
  size_words: string;
  photo: string;
  sub_item: SubItem[];

  constructor()
  {
    this.category_id = '';
    this.category_name = '';
    this.category_name_trans = '';
    this.cooking_name_trans = '';
    this.cooking_ref = '';
    this.discount = '';
    this.discounted_price = 0;
    this.ingredients = '';
    this.item_id = '';
    this.item_name_trans = '';
    this.non_taxable = 0;
    this.normal_price = '';
    this.order_notes = '';
    this.qty = '';
    this.size_name_trans = '';
    this.size_words = '';
    this.photo = '';
    this.sub_item = [];
  }
}

export class Total
{
  calculation_method: number;
  cart_tip_percentage: string;
  curr: number;
  delivery_charges: number;
  discounted_amount: number;
  less_voucher: number;
  less_voucher_orig: number;
  merchant_discount_amount: number;
  pts_redeem_amt: number;
  pts_redeem_amt_orig: number;
  subtotal: number;
  total: number;
  tax: string;
  tax_amt: string;
  taxable_total: number;
  tips: string;
  tips_percent: string;
  voucher_type: string;
  voucher_types: string;
  voucher_value: string;
  mid: string;

  constructor(
    calculation_method:number = 0, cart_tip_percentage: string = '',
    curr: number = 0, delivery_charges: number = 0,
    discounted_amount: number = 0, less_voucher: number = 0,
    less_voucher_orig: number = 0, merchant_discount_amount: number = 0,
    pts_redeem_amt: number = 0, pts_redeem_amt_orig: number = 0,
    subtotal: number = 0, total: number = 0,
    tax: string = '', tax_amt: string = '',
    taxable_total: number = 0, tips: string = '',
    tips_percent: string = '', voucher_type: string = '',
    voucher_types: string = '', voucher_value: string = '',
    mid: string = ''
  )
  {
    this.calculation_method = calculation_method;
    this.cart_tip_percentage = cart_tip_percentage;
    this.curr = curr;
    this.delivery_charges = delivery_charges;
    this.discounted_amount = discounted_amount;
    this.less_voucher = less_voucher;
    this.less_voucher_orig = less_voucher_orig;
    this.merchant_discount_amount = merchant_discount_amount;
    this.pts_redeem_amt = pts_redeem_amt;
    this.pts_redeem_amt_orig = pts_redeem_amt_orig;
    this.subtotal = subtotal;
    this.total = 0;
    this.tax = tax;
    this.tax_amt = tax_amt;
    this.taxable_total = taxable_total;
    this.tips = tips;
    this.tips_percent = tips_percent;
    this.voucher_type = voucher_type;
    this.voucher_types = voucher_types;
    this.mid = mid;

  }
}

export class CartData
{
  item: Item[];
  total: Total;
  constructor(item: Item[] = [], total: Total = new Total())
  {
    this.item = item;
    this.total = total;
  }
}


export class SubItem
{
  addon_category: string;
  addon_name: string;
  addon_price: any;
  addon_calculate_type: string;
  addon_calculate_type_cat_id: string;
  addon_qty: any;
  sub_item_id: string;
  subcat_id: any;
}
