import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResponse} from '../class/apiResponse';
import {FunctionsService} from '../services/functions.service';
import {Item} from '../class/item';
import {ApiService} from '../services/api.service';
import {ModalService} from '../services/new/modal.service';
import {CartService} from '../services/new/cart.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  catId: string;
  itemId: string;
  row: string;

  itemSelect: any;

  qtyItemSelect: any;



  constructor()
  {
    this.catId = '';
    this.itemId = '';
    this.row = '';
    this.itemSelect = {};
    this.qtyItemSelect = {};
  }

  openModalEdit(catId, itemId, row): void
  {
    this.catId = catId;
    this.itemId = itemId;
    this.row = row;
  }

}
