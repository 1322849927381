import {NgModule} from '@angular/core';

import { RouterModule, Routes} from '@angular/router';

import { HomeComponent } from './home/home.component' ;

import {PaymentComponent} from './payment/payment.component';

import {NetworkErroComponent} from './network-erro/network-erro.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {PaymentGuard} from './guards/payment.guard';
import {WaitingOrderComponent} from './waiting-order/waiting-order.component';


const appRoutes: Routes = [
  {path: '',  component: HomeComponent},
  {path: 'home/:token', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'payment', component: PaymentComponent, canActivate: [PaymentGuard], canDeactivate: [PaymentGuard]},
  {path: 'aguardando-pedido/:orderId', component: WaitingOrderComponent},
  {path: 'networkError', component: NetworkErroComponent},
  {path: '**', component: NotFoundComponent},
];




@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]

})
export class AppRoutingModule {}
